<template>
  <section class="bg-darkgray">
    <div class="relative h-96 flex justify-center items-center">
      <img class="w-full h-full object-cover" src="@/assets/banner.jpg" />
      <div class="absolute inset-0 bg-black bg-opacity-50 flex items-center justify-center">
        <AnimateText title="OUR PROJECTS" />
      </div>
    </div>
  </section>

  <section>
    <div class="md:container mx-auto px-6 py-12">
      <div class="bg-indigo-500 relative flex justify-center items-center h-20 mt-12" v-if="loading">
        <div class="rounded animate-spin ease duration-300 w-5 h-5 border-2 border-primary"></div>
      </div>
      <div class="py-12" v-else>
        <div class="grid grid-cols-1 md:grid-cols-2 gap-0">
          <Thumbnail
            v-for="project in projects"
            :key="`project-${project.id}`"
            :categories="project.categories.join(', ')"
            :thumbnail="project.headline"
            :title="project.title"
            :route="`/project/${project.slug}`"
          />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { useHead } from '@vueuse/head';
import AnimateText from '@/components/Animation/AnimateText.vue';
import Thumbnail from '@/components/Thumbnail.vue';

export default {
  components: {
    AnimateText,
    Thumbnail,
  },
  setup() {
    useHead({
      title: `Our Projects | ${process.env.VUE_APP_NAME}`,
    });
  },
  data() {
    return {
      loading: true,
      projects: [],
    };
  },
  methods: {
    get() {
      this.loading = true;
      this.$http.get(`/project.php`).then((response) => {
        this.projects = response.data;
        this.loading = false;
      });
    },
  },
  mounted() {
    this.get();
  },
};
</script>
